import React from 'react'
import {
  Button, Container, Divider, Grid, Header, Image, Icon, Segment,
} from 'semantic-ui-react'
import avatar from './img/avatar.png'

const App = () => (
  <div>
    <Segment basic inverted size='massive'>
      <Container text>
        <Header as='h2' inverted>
          <Image avatar bordered circular src={ avatar } />
          juxton.link
        </Header>
      </Container>
    </Segment>

    <Segment basic padded='very'>
      <Container text>
        <Header as='h1'>Follow Me</Header>
        <Divider />
        
        <Divider hidden />

        <Grid stackable columns={ 4 }>
          <Grid.Column width={ 4 }>
            <Button icon href='https://twitter.com/juxton' size='big' labelPosition='left' color='twitter'>
              <Icon name='twitter' />
              Twitter
            </Button>
          </Grid.Column>
          <Grid.Column width={ 4 }>
            <Button icon href='https://juxton.live' size='big' labelPosition='left' color='purple'>
              <Icon name='twitch' />
              Twitch
            </Button>
          </Grid.Column>
          <Grid.Column width={ 4 }>
            <Button icon href='https://juxton.me' size='big' labelPosition='left' color='grey'>
              <Icon name='medium m' />
              Medium
            </Button>
          </Grid.Column>
          <Grid.Column width={ 4 }>
            <Button icon href='https://audius.co/juxton' size='big' labelPosition='left' color='purple'>
              <Icon name='radio' />
              Audius
            </Button>
          </Grid.Column>
        </Grid>

        <Divider hidden />

        <Header as='h1'>Decentraland</Header>
        <Divider />

        <Divider hidden />
        
        <Grid>
          <Grid.Column>
            <Button icon href='https://juxton.land' size='big' labelPosition='left' color='pink'>
              <Icon name='world' />
              Franky's Tavern
            </Button> 
          </Grid.Column>
        </Grid>

        <Divider hidden />

        <Header as='h1'>VRM</Header>
        <Divider />

        <Divider hidden />

        <Grid>
          <Grid.Column>
            <Button icon href='https://twitter.com/coolboardnft' size='big' labelPosition='left' color='twitter'>
              <Icon name='twitter' />
              Cool Board
            </Button> 
          </Grid.Column>
        </Grid>

        <Divider hidden />

        <Header as='h1'>The Sandbox</Header>
        <Divider />
        
        <Divider hidden />
        
        <Grid>
          <Grid.Column>
            <Button icon href='https://www.sandbox.game/en/map/?x=21&y=26' size='big' labelPosition='left' color='yellow'>
              <Icon name='world' />
              Meta Sonora
            </Button> 
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>

    <Segment basic padded='very' secondary>
      <Container text>
        <Header as='h2'>Referral Links</Header>
        <Divider />

        <Header as='h3'>Decentraland</Header>
        <p>
          Decentraland is a decentralized virtual reality world that is powered by the Ethereum blockchain. In this virtual world, you can purchase land, build it, monetize, and immerse into the applications and content that is built by other users.
        </p>
        <Button icon primary href='https://decentraland.org/?referral=HDRWAN' size='large' labelPosition='left'>
          <Icon name='user' />
          Join Decentraland
        </Button>

        <Divider hidden />

        <Header as='h3'>The Sandbox</Header>
        <p>
          The Sandbox is a community-driven platform where creators can monetize voxel assets and gaming experiences on the Ethereum blockchain.
        </p>
        <Button icon primary href='https://www.sandbox.game/login/?r=B28SdiZE65Az2cZLthVC3B' size='large' labelPosition='left'>
          <Icon name='user' />
          Join The Sandbox
        </Button>

        <Divider hidden />

        <Header as='h3'>Ledger Hardware Wallet</Header>
        <p>
          Ledger has developed hardware wallet technology that provides you with the highest level of security for your crypto assets. Ledger hardware wallets empower you with the ownership and control of your private keys.
        </p>
        <Button icon primary href='https://shop.ledger.com/pages/ledger-nano-x?r=add9fefca725' size='large' labelPosition='left'>
          <Icon name='shop' />
          Shop Ledger
        </Button>

        <Divider hidden />
      </Container>
    </Segment>
  </div>
)

export default App